@import "./forms";
@import "./components";
@import "./typography";
@import "./buttons";

@import "../../node_modules/tailwindcss/base";
@import "../../node_modules/tailwindcss/components";
@import "../../node_modules/tailwindcss/utilities";

@import url("https://fonts.googleapis.com/css2?family=Amatic+SC&family=Poppins&display=swap");

.custom-card {
  border-radius: 4px !important;
  padding: 8px !important;
}

.custom-card .ant-card-body {
  padding: 8px !important;
}

.custom-card-align-center .ant-card-head {
  text-align: center !important;
}

.custom-description > .ant-descriptions-view {
  @apply bg-white rounded-xl overflow-hidden shadow;
}

.custom-description > .ant-descriptions-header.ng-star-inserted > div {
  @apply title;
}

.bg-elior-600 {
  background-color: $color-600 !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: $color-500 !important ;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: $color-500 !important;
}

.ant-radio:hover .ant-radio-inner {
  border-color: $color-500 !important;
}

.ant-radio-checked::after {
  border: 1px solid $color-500 !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: $color-500 !important;
}

.ant-radio-inner::after {
  background-color: $color-500 !important;
}

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px #1c9a8135 !important;
}

.ant-spin-dot-item {
  background-color: $color-500 !important;
}
