@import "theme/variables.scss";

@font-face {
  font-family: "Elior";
  src: url("../assets/fonts/Elior/Elior-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Elior-bold";
  src: url("../assets/fonts/Elior/Elior-ExtraBold.ttf") format("truetype");
}

.title {
  @apply text-xl font-semibold tracking-tight;
}

.subtitle {
  @apply text-base font-semibold tracking-tight;
}

.basic-label {
  @apply block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5;
}
.amatic-h1 {
  //styleName: typography/H1/font1;
  font-family: Amatic SC;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0.0012em;
  text-align: left;
}

.amatic-h2 {
  //styleName: typography/H2/font1;
  font-family: Amatic SC;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.0012em;
  text-align: left;
}

.amatic-h3 {
  //styleName: typography/H3/font1;
  font-family: Amatic SC;
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
  text-align: left;
}

.amatic-h4 {
  //styleName: typography/H4/font1;
  font-family: Amatic SC;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.0012em;
  text-align: left;
}

.elior-h1 {
  //styleName: typography/H1/font2;
  font-family: "Elior-bold";
  font-size: 32px;
  font-weight: 800;
  line-height: 38px;
  letter-spacing: 0.0012em;
  text-align: left;
}

.elior-h2-bold {
  //styleName: typography/H2/bold/font2;
  font-family: "Elior-bold";
  font-size: 24px;
  font-weight: 800;
  line-height: 36px;
  letter-spacing: 0.0012em;
  text-align: left;
}

.elior-h3-regular {
  //styleName: typography/H3/regular/font2;
  font-family: "Elior";
  font-size: 22px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0.0012em;
  text-align: left;
}

.elior-h3-bold {
  //styleName: typography/H3/regular/font2;
  font-family: "Elior-bold";
  font-size: 22px;
  font-weight: 800;
  line-height: 33px;
  letter-spacing: 0.0012em;
  text-align: left;
}

.elior-h4-regular {
  //styleName: typography/H4/regular/font2;
  font-family: "Elior";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.0012em;
  text-align: left;
}

.elior-h4-bold {
  //styleName: typography/H4/bold/font2;
  font-family: "Elior-bold";
  font-size: 20px;
  font-weight: 800;
  line-height: 30px;
  letter-spacing: 0.0012em;
  text-align: left;
}

.elior-h5-regular {
  //styleName: typography/body/large/regular;
  font-family: "Elior";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.body-large-regular {
  //styleName: typography/body/large/regular;
  font-family: "Elior";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.body-large-bold {
  //styleName: typography/body/large/regular;
  font-family: "Elior-bold";
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.body-small-regular {
  //styleName: typography/body/small/regular;
  font-family: "Elior";
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.body-small-bold {
  //styleName: typography/body/small/bold;
  font-family: "Elior-bold";
  font-size: 14px;
  font-weight: 800;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.button {
  font-family: "Elior";
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.0012em;
  text-align: center;
  text-transform: uppercase;
}

.caption {
  //styleName: typography/caption;
  font-family: "Elior";
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.0012em;
  text-align: left;
}

.link-regular {
  //styleName: typography/link/regular;
  font-family: "Elior";
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.0012em;
  text-align: left;
  text-decoration: underline;
}

.link-bold {
  //styleName: typography/link/bold;
  font-family: "Elior-bold";
  font-size: 14px;
  font-weight: 800;
  line-height: 14px;
  letter-spacing: 0.0012em;
  text-align: left;
  text-decoration: underline;
}

.input-label-typography {
  //styleName: typography/tag/regular;
  font-family: "Elior";
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.0012em;
  text-align: left;
  text-transform: uppercase;
}

.tag-bold {
  //styleName: typography/tag/bold;
  font-family: "Elior-bold";
  font-size: 14px;
  font-weight: 800;
  line-height: 14px;
  letter-spacing: 0.0012em;
  text-align: left;
}
