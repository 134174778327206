.joyfood-form.form-group {
  @apply space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0 px-6;

  dbg-form-item {
    @apply sm:grid sm:grid-cols-3 sm:items-start sm:gap-x-4 sm:py-6;

    .component {
      @apply mt-2 sm:col-span-2 sm:mt-0;

      .input-container {
        @apply flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md;
      }

      .input-text {
        @apply block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6;
      }

      .input-select {
        @apply block w-full rounded-md border-0 py-1.5 pl-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6;
      }
    }
  }
}
