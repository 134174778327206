.action-button {
  @apply font-medium rounded-lg text-sm px-5 py-1.5 text-center;
}
.action-button-sm {
  @apply font-medium rounded-lg text-sm px-2 py-1 text-center;
}

.icon-button {
  @apply flex items-center gap-2;
}

.border-button {
  @apply border enabled:[&:not(:hover)]:bg-white #{!important};
}

.danger-button {
  @apply enabled:text-red-700 enabled:hover:text-white enabled:border-red-700 enabled:hover:bg-red-700 enabled:dark:border-red-500 enabled:dark:text-red-500 enabled:dark:hover:text-white enabled:dark:hover:bg-red-500 enabled:dark:focus:ring-red-700;
  @apply disabled:opacity-60 disabled:cursor-not-allowed;
}

.white-button {
  @apply enabled:text-gray-700 enabled:hover:text-gray-900 enabled:border-gray-700 enabled:dark:border-gray-500 enabled:dark:text-gray-500;
  @apply disabled:opacity-60 disabled:cursor-not-allowed;
}

.joyfood-button {
  @apply enabled:text-joyfood-700 enabled:hover:text-white enabled:border-joyfood-700 enabled:hover:bg-joyfood-700 enabled:dark:border-joyfood-500 enabled:dark:text-joyfood-500 enabled:dark:hover:text-white enabled:dark:hover:bg-joyfood-500 enabled:dark:focus:ring-joyfood-700;
  @apply disabled:opacity-60 disabled:cursor-not-allowed;
}

.joyfood-list-action-button {
  @apply font-medium rounded-lg text-sm px-5 py-1.5 text-center;
  @apply flex items-center gap-2;
  @apply enabled:text-joyfood-700 enabled:hover:text-white enabled:border-joyfood-700 enabled:hover:bg-joyfood-700 enabled:dark:border-joyfood-500 enabled:dark:text-joyfood-500 enabled:dark:hover:text-white enabled:dark:hover:bg-joyfood-500 enabled:dark:focus:ring-joyfood-700;
  @apply disabled:opacity-60 disabled:cursor-not-allowed;
  @apply border enabled:[&:not(:hover)]:bg-white #{!important};
}
