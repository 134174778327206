@tailwind base;
@tailwind components;
@tailwind utilities;

.ant-table-thead>tr>th:first-child {
  @apply pl-8 #{!important}
}

.ant-table-tbody>tr>td:first-child {
  @apply pl-8 #{!important}
}

.ant-table-column-sorters {
  @apply gap-2;
  justify-content: unset;

  .ant-table-column-title {
    flex: unset;
  }
}

.ant-table-row {
  &.disabled {
    @apply text-slate-500;
  }

  &.selectable {
    cursor: pointer;
  }
}