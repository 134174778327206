@import '../variables/colors.scss';

.ant-steps-item-process {
  .ant-steps-item-container {
    .ant-steps-item-icon {
      background-color: $color-600 !important;
    }
  }
}

.ant-steps-vertical {
  .ant-steps-item {
    .ant-steps-item-icon {
      border-color: $color-600 !important;
    }
  }
}
.ant-steps-item-finish {
  .ant-steps-item-icon {
    .ant-steps-icon {
      color: $color-600 !important;
    }
  }
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: $color-600 !important;
}
