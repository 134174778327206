@import '../variables/colors.scss';

.focus\:ring-indigo-600:focus {
  --tw-ring-color: #{$color-600} !important;
}

.ant-picker:hover {
  border: inherit;
}
.ant-picker-focused {
  border-width: 2px !important;
  border-color: $color-600 !important;
}

.ant-picker-input > input:focus,
.ant-picker-input > input-focused {
  border-color: $color-600 !important;
}

.ant-picker-range .ant-picker-active-bar {
  background-color: $color-600 !important;
}

.ant-select {
  border-radius: 6px !important;
  height: 36px !important;
  border-width: 1px !important;
  width: 100% !important;
  padding: 0 !important;
}

.ant-select-show-search.ant-select .ant-select-selector {
  box-shadow: none !important;
  height: 36px !important;
  border-radius: 6px !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border: none !important;
  border-radius: 0px !important;
}

// Time Picker Dropdown
.ant-picker-panel-container {
  border-radius: 6px;

  .ant-picker-content {
    .ant-picker-time-panel-column {
      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 6px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }
    }
  }

  .ant-picker-footer {
    .ant-picker-ok {
      button {
        border: 1px solid $color-600;
        border-radius: 0.375rem;
        background-color: $color-600;
        padding: 0px 10px;

        &:hover {
          border-color: $color-500;
          background-color: $color-500;
        }
      }
    }
  }
}

[type='text']:focus,
input:where(:not([type])):focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  border-width: 2px !important;
  border-color: $color-600 !important;
  --tw-ring-color: transparent !important;
}
