// Colors
$color-50: #eff8f6;
$color-100: #d2ebe6;
$color-200: #8dccbf;
$color-300: #52b5a1;
$color-400: #33a08a;
$color-500: #1c9a80;
$color-600: #14745f;
$color-700: #136755 !important;
$color-800: #0c3f35;
$color-900: #09332b;

$elior-secondary-color: #d2ebe6;
$grey: grey;
$white: #ffffff;

// Menu
$block-link--hover: #f8f8f8;
$block-link--selected: #f6f6f6 !important;
