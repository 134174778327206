@import '../variables/colors.scss';
@import '../variables/layout.scss';
@tailwind base;
@tailwind components;
@tailwind utilities;

.ant-tabs-nav {
    @apply px-4 sm:px-6 lg:px-8;
    height: 56px;
}

.ant-tabs-tab {
    @apply font-semibold;
}